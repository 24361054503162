import { lazy, Suspense, useState } from "react";
import "./App.css";
import "./Bootstrap/css/bootstrap.min.css"
import LocalRender from "./Components/Localization/Components/LocalRender";
import Localization from "./Components/Localization/Components/Localization";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { HiMiniArrowsRightLeft } from "react-icons/hi2"
import { Context } from "./Context/ContextProvider";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
const RoutingConfig = lazy(() => import("./Components/RoutingConfig"))
const Auth = lazy(() => import("./Components/Auth/Auth"))
const AMSPrivateRoute = lazy(() => import("./PrivateRoute/AMSPrivateRoute"))
const CreatePassword = lazy(() => import("./Components/Auth/CreatePassword"))
function App() {
  const { language, toggleLanguage } = Context();
  const { t, i18n } = useTranslation();
  const [isRtl, setIsRtl] = useState(false);

  const [broken, setBroken] = useState(window.matchMedia("(min-width: 992px)").matches);
  useEffect(() => {
    const handleResize = () => {
      setBroken(window.matchMedia("(min-width: 992px)").matches);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeLanguage = (lng) => {
    lng == "Arabic" ? setIsRtl(true) : setIsRtl(false);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    changeLanguage(language)
  }, [language])
  return (
    <div className="" style={{ direction: isRtl ? "rtl" : "ltr" }} >
      <Suspense fallback={() => <div>Loading ....</div>}>
        <ToastContainer />
        <Router >
          <Routes>
            <Route path='*' element={<Auth broken={broken} />} />
            <Route pathname="/auth" element={<Auth broken={broken} />} />
            <Route path="/createpassword/:token" element={<CreatePassword broken={broken} />} />
            <Route path="v1/*" element={<AMSPrivateRoute><RoutingConfig broken={broken} /></AMSPrivateRoute>} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
